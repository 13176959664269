<template>
  <b-navbar type="is-black" wrapper-class="public-alignment-navbar">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/ligas' }">
        <img
          src="../../../assets/imagens/logotipo_green_10.png"
          alt="Escala 10"
          :style="$mq == 'sm' ? 'width: 75px;' : ''"
        />
        <b-icon
          v-if="!isEscala10"
          pack="fas"
          icon="plus"
          style="margin: 0 0.5em"
          size="is-small"
        ></b-icon>
        <img
          v-if="!isEscala10"
          :src="influencer.url_foto"
          :style="$mq == 'sm' ? 'width: 75px;' : ''"
        />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item v-if="$mq !== 'sm'" href="#section-plans">
        Planos
      </b-navbar-item>
    </template>
    <template slot="end">
      <div class="bar-alignment-buttons">
        <b-button
          tag="router-link"
          to="/login?action=login"
          outlined
          type="is-primary"
          :expanded="$mq === 'sm'"
        >
          Entrar
        </b-button>
        <b-button
          :expanded="$mq === 'sm'"
          tag="router-link"
          to="/login?action=cad"
          type="is-primary"
        >
          Cadastrar
        </b-button>
      </div>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "Navbar",
  props: {
    influencer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isEscala10() {
      return this.influencer?.nome_midia?.includes("Escala");
    },
  },
};
</script>

<style lang="scss">
.public-alignment-navbar {
  .bar-alignment-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .button:first-child {
      margin-right: 1em;
    }

    @media (max-width: 768px) {
      & {
        padding: 0em 1em;
      }
      .button:first-child {
        margin-right: 0em;
        margin-bottom: 1em;
      }
    }
  }

  @media (min-width: 768px) {
    display: flex;
    width: 70%;
    margin: 0 auto;
  }
}
</style>
