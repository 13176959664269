<template>
  <section id="section-plans">
    <div class="alignment">
      <div class="plans-container">
        <h1>{{ title }}</h1>

        <div class="columns">
          <div
            v-for="(plan, index) of plansView"
            :key="plan.codigo"
            class="column"
            :style="{ 'max-width': $mq !== 'sm' ? '50%' : 'none' }"
          >
            <div class="plan-card" :better="betterPlan.index === index">
              <div class="plan-title">
                {{ plan.periodo }}
              </div>
              <div class="plan-content">
                <div v-if="plan.comDesconto" class="value-with-cupom">
                  <p>
                    De:
                    <span class="span-value"
                      >R$ {{ plan.valor.toFixed(2).replace(".", ",") }}</span
                    >
                    <b-tag size="is-small" type="is-pigeon"
                      >{{ plan.cupom.desconto }}%</b-tag
                    >
                  </p>
                  <h3>
                    R$
                    <span>{{ valueFormatted(plan.cupom).integer }}</span
                    >,{{ valueFormatted(plan.cupom).decimals }}
                  </h3>
                </div>
                <div v-else class="value-without-cupom">
                  <h3>
                    R$ <span>{{ valueFormatted(plan).integer }}</span
                    >,{{ valueFormatted(plan).decimals }}
                  </h3>
                </div>

                <div class="plan-details">
                  <div v-html="formaterDetails(plan.detalhes)"></div>
                </div>

                <b-button
                  tag="router-link"
                  :to="
                    `/assinatura?plan=${plan.codigo}&influencer=${influencer.id}`
                  "
                  target="_blank"
                  :type="getTypeFromPlan(plan)"
                >
                  <b style="font-size: 0.9em">ASSINAR</b>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionPlans",
  props: {
    plans: {
      type: Array,
      required: true,
    },
    influencer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      plansView: [],
      items: [
        { label: "Página de Atletas", isFree: true },
        { label: "Mínimo para valorizar", isFree: true },
        { label: "Ligas", isFree: true },
        { label: "Desempenho do seu Time", isFree: true },
        { label: "Detalhes de Atletas", isFree: false },
        { label: "Cruzamento de Scouts", isFree: false },
        { label: "Mapa de Gols e Assistências", isFree: false },
        { label: "Análise de arbitragem", isFree: false },
        { label: "Análise de Jogos", isFree: false },
        { label: "Ranking de Atletas", isFree: false },
        { label: "Ranking de Clubes", isFree: false },
        { label: "Análise de Clubes", isFree: false },
      ],
    };
  },
  computed: {
    title() {
      if (this.isEscala10) {
        return "Planos";
      }
      return `Planos E10 + ${this.influencer?.nome_midia}`;
    },
    isEscala10() {
      return this.influencer?.nome_midia?.includes("Escala");
    },
    betterPlan() {
      const plansValues = this.plans.map((p, index) => {
        const value = p.cupom?.valor ? p.cupom.valor : p.valor;
        return { index, value };
      });

      return plansValues.sort((a, b) => b.value > a.value)[0];
    },
  },
  watch: {
    plans(newValue) {
      this.plansView = [...newValue];
    },
  },
  methods: {
    getIconFromItem(plan, item) {
      return this.isValidItem(plan, item) ? "check" : "times";
    },
    getTypeFromPlan(plan) {
      return plan.valor > 0 ? "is-primary" : "is-black";
    },
    isValidItem(plan, item) {
      if (plan.valor === 0) {
        return item.isFree ? true : false;
      }
      return true;
    },
    valueFormatted(plan) {
      if (plan.valor === 0) {
        return { integer: "0", decimals: "00" };
      }

      const [integer, decimals] = plan.valor.toFixed(2).split(".");
      return { integer, decimals };
    },
    formaterDetails(details) {
      let bodyDetails = details.replaceAll(
        "{check}",
        `<i class="fas fa-check"></i>`
      );
      bodyDetails = bodyDetails.replace(
        "{no-check}",
        `<i class="fas fa-times"></i>`
      );

      return bodyDetails;
    },
    hasCupom(cupom) {
      return Object.keys(cupom).length > 0;
    },
  },
};
</script>

<style lang="scss">
#section-plans {
  width: 100%;
  min-height: 100vh;
  display: table;
  background-color: #f2f2f2;
  padding-bottom: 1em;

  .plans-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      margin-top: 1em;
      font-weight: 900;
      font-size: 3em;
      text-transform: uppercase;
      color: black;
      margin-bottom: 1.5em;
    }

    @media (max-width: 768px) {
      h1 {
        font-size: 1.5em;
      }
    }
  }

  .plan-card {
    width: 100%;
    border-radius: 15px;
    background-color: white;
    border: 2px solid white;
    position: relative;
    padding: 2em;
    height: 100%;

    .plan-title {
      border-radius: 7px;
      position: absolute;
      padding: 0.5em 1em;
      text-transform: uppercase;
      font-weight: bolder;
      top: -20px;
      left: 2em;
      background-color: black;
      color: white;
    }

    &[better] {
      border-color: #00f0f8;

      .plan-title {
        background-color: #00f0f8;
        color: black;
      }
    }

    .plan-content {
      color: black;
      margin-top: 1em;
      text-align: center;

      .value-with-cupom {
        height: 90px;
        margin-bottom: 1.3em;

        p {
          .span-value {
            text-decoration: line-through;
          }

          .tag {
            margin-left: 1em;
          }
        }
      }

      .value-without-cupom {
        height: 90px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 1.3em;
      }

      h3 {
        font-size: 1em;
        font-weight: bolder;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4em;

        span {
          font-size: 4em;
          margin-left: 0.1em;
          font-weight: bolder;
        }
      }

      .plan-details {
        margin-bottom: 2em;
        font-size: 0.8em;
        padding: 0 10%;
        min-height: 250px;

        ol {
          list-style-type: none !important;
          text-align: left;

          li {
            i {
              width: 15px;
              margin-right: 1em;

              .fa-times {
                opacity: 0.5 !important;
              }
            }
          }
        }

        .level {
          opacity: 0.5 !important;
          margin-bottom: 0.5em;

          &[valid] {
            opacity: 1 !important;
          }

          .level-item {
            padding-left: 10%;
            justify-content: start;
          }
        }
      }
    }
  }
}
</style>
