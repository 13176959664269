<template>
  <div id="influencer-page">
    <b-navbar
      type="is-black"
      wrapper-class="app-panel-alignment-navbar"
      fixed-top
    >
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img
            src="../../../assets/imagens/logotipo_green_10.png"
            alt="Escala 10"
            :style="$mq == 'sm' ? 'width: 120px;' : ''"
          />
        </b-navbar-item>
      </template>
      <template slot="end">
        <div class="app-panel-nav-align-buttons">
          <b-button
            :expanded="isMobile"
            outlined
            type="is-primary"
            size="is-small"
            tag="router-link"
            to="/login"
          >
            <b>Entrar</b>
          </b-button>
        </div>
        <div class="app-panel-nav-align-buttons">
          <b-button
            :expanded="isMobile"
            type="is-primary"
            size="is-small"
            tag="router-link"
            to="/login"
          >
            <b>Cadastrar</b>
          </b-button>
        </div>
      </template>
    </b-navbar>
    <SectionHome :influencer="influencerData"></SectionHome>
    <SectionFishing></SectionFishing>
    <SectionPlans :plans="plans" :influencer="influencerData"></SectionPlans>
    <E10Footer></E10Footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Navbar from "../components/Navbar.vue";
import SectionHome from "../components/SectionHome.vue";
import SectionPlans from "../components/SectionPlans.vue";
import SectionFishing from "../components/SectionFishing.vue";
import E10Footer from "@/layouts/components/Footer.vue";

export default {
  name: "Influencers",
  components: {
    SectionHome,
    SectionPlans,
    E10Footer,
    SectionFishing,
    Navbar,
  },
  data() {
    return {
      influencerData: {},
      plans: [],
    };
  },
  computed: {
    influencerId() {
      return this.$route.params.id || 10;
    },
    cupom() {
      return this.$route.query.cupom ? this.$route.query.cupom : "";
    },
  },
  methods: {
    ...mapActions(["getPlans", "getInfluencerById"]),
    async loadInfluencer() {
      this.influencerData = await this.getInfluencerById(this.influencerId);

      if (!this.influencerData) {
        this.$toasted.error("Influenciador não identificado", {
          duration: 3000,
          position: "top-center",
        });
      }
    },
    loadPlans() {
      this.getPlans({
        cpf: "",
        email: "",
        cupom: this.cupom,
        influencer: this.influencerId,
      }).then((plansResponse) => {
        const isValidPlans = plansResponse.length > 0;

        if (isValidPlans) {
          this.plans = plansResponse.filter((p, i) => i < 3);
        } else {
          this.validCupom();
        }
      });
    },
    validCupom() {
      if (this.cupom !== "") {
        this.$toasted.show("Cupom Inválido", {
          position: "top-center",
          duration: 3000,
          type: "error",
          className: "toasted-class",
        });
      }
    },
  },
  async created() {
    const loader = this.$loading.show();

    if (isNaN(this.influencerId)) {
      this.$router.push({ path: "/" });
    }

    await this.loadInfluencer();
    await this.loadPlans();

    loader.hide();
  },
};
</script>

<style lang="scss">
#influencer-page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .alignment {
    height: 100%;
    width: 70%;
    margin: 0 auto;

    @media (max-width: 768px) {
      width: calc(100% - 1.5em);
    }
  }

  .button.is-black {
    &:hover {
      color: #00f0f8 !important;
    }
  }
  .app-panel-alignment-navbar {
    @media (min-width: 768px) {
      display: flex;
      width: 90%;
      margin: 0 auto;
    }

    .app-panel-nav-align-buttons {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 0.25em;

      @media (max-width: 768px) {
        .button {
          margin: 0.75em 0;

          &[logout] {
            margin-top: 1em;
          }
        }
      }
    }
  }
}
</style>
