<template>
  <section id="section-home">
    <div class="alignment">
      <div class="block-title">
        <div class="avatar-aligment">
          <div class="avatar">
            <img src="../../../assets/logotipo_black.png" />
          </div>
          <b-icon v-if="!isEscala10" pack="fas" icon="plus"></b-icon>
          <div class="avatar" v-if="!isEscala10">
            <img :src="influencer.url_foto" />
          </div>
        </div>
        <h1>QUER MUDAR DE PATAMAR<br />EM TODOS OS SEUS FANTASY GAMES?</h1>
        <h2>
          O Escala10 é a primeira plataforma brasileira de análises dos maiores
          campeonatos do mundo.<br />Só aqui você tem acesso as estatísticas do
          Cartola FC e dos campeonatos da Elite Europeia.
        </h2>
        <div class="buttons">
          <b-button
            class="button-width-large"
            size="is-medium"
            type="is-primary"
            tag="a"
            href="#section-plans"
          >
            <b>Eu quero Mitar!</b>
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionHome",
  props: {
    influencer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isEscala10() {
      return this.influencer?.nome_midia?.includes("Escala");
    },
  },
  methods: {
    handleAnchor(refName) {
      let element = document.body;
      const to = document.getElementById(refName);
      const duration = 600;
      this.animate(element, to, duration);
    },
    animate(element, to, duration) {
      const difference = to.offsetTop - element.offsetTop;
      const perTick = (difference / duration) * 10;
      let self = this;
      setTimeout(function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        self.animate(element, to, duration);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#section-home {
  width: 100%;
  height: 70vh;
  display: table;
  background-image: url("/images/section_home.png");
  background-position: center;
  background-size: cover;

  @media (max-width: 768px) {
    padding: 1em 0;
  }

  .block-title {
    text-align: left;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .avatar-aligment {
      display: flex;
      align-items: center;
      margin-bottom: 2em;

      .avatar {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        overflow: hidden;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #00f0f8;
        margin: 0 1em;
        -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 240, 248, 1);
        -moz-box-shadow: 0px 0px 12px 0px rgba(0, 240, 248, 1);
        box-shadow: 0px 0px 12px 0px rgba(0, 240, 248, 1);

        &:first-child {
          margin-left: 0em;
        }

        img {
          transform: scale(1.5);
        }

        @media (max-width: 768px) {
          width: 70px;
          height: 70px;
        }
      }
    }

    h1 {
      font-weight: 900;
      font-size: 3em;
      line-height: 1.2em;
      text-transform: uppercase;
      text-shadow: 0px 0px 6px rgb(0, 0, 0);
    }
    h2 {
      margin-top: 2em;
      font-weight: 600;
      text-shadow: 0px 0px 6px rgb(0, 0, 0);
    }

    .buttons {
      margin-top: 1em;

      .button-width-large {
        width: 25%;

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }

    @media (max-width: 768px) {
      h1 {
        font-size: 1.5em;
      }
    }
  }
}
</style>
