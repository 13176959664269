<template>
  <section id="section-fishing" class="is-background-default">
    <div class="columns is-multiline">
      <div class="column section-fish is-full">
        <div class="alignment">
          <div class="columns">
            <div class="column is-half control-text">
              <h3 class="fish-title is-1">ELITE</h3>
              <p class="subtitle">
                Além das melhores estatísticas do Cartola FC você ainda tem
                acesso a informações detalhadas dos jogadores e clubes dos
                principais campeonatos europeus, com análises exclusivas para
                ajudar na escalação dos seus times em qualquer fantasy game.
              </p>
            </div>
            <div class="column is-half centralize">
              <h4 class="fish-subtitle">
                Estatísticas da elite europeia
              </h4>

              <img class="fish-image" src="../../../assets/imagens/2.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="column section-fish is-full">
        <div class="alignment">
          <div class="columns">
            <div v-if="$mq !== 'sm'" class="column is-half centralize">
              <h4 class="fish-subtitle">
                SCOUTS DE ATAQUE, DEFESA E MÉDIA GERAL DOS ATLETAS
              </h4>

              <img class="fish-image" src="../../../assets/imagens/3.png" />
            </div>
            <div class="column is-half control-text">
              <h3 class="fish-title is-1">Scouts</h3>
              <p class="subtitle">
                Organize facilmente a lista de atletas através de scouts de
                ataque, defesa ou média geral, e descubra quem é o melhor atleta
                em cada fundamento.
              </p>
            </div>
            <div v-if="$mq === 'sm'" class="column is-half centralize">
              <h4 class="fish-subtitle">
                SCOUTS DE ATAQUE, DEFESA E MÉDIA GERAL DOS ATLETAS
              </h4>

              <img class="fish-image" src="../../../assets/imagens/3.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="column section-fish is-full">
        <div class="alignment">
          <div class="columns">
            <div class="column is-half control-text">
              <h3 class="fish-title is-1">
                ANÁLISES
              </h3>
              <p class="subtitle">
                Encontre os jogos com maior potencial, analisando as pontuações
                médias e scouts cedidos pelos clubes no período e mando
                desejado.
              </p>
            </div>
            <div class="column is-half centralize">
              <h4 class="fish-subtitle">
                CRUZAMENTO DE SCOUTS CONQUISTADOS VERSUS SCOUTS CEDIDOS
              </h4>
              <img class="fish-image" src="../../../assets/imagens/4.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="column section-fish is-full">
        <div class="alignment">
          <div class="columns">
            <div v-if="$mq !== 'sm'" class="column is-half centralize">
              <h4 class="fish-subtitle">
                PRIMEIRA PLATAFORMA DE ANÁLISE EXCLUSIVA PARA FANTASY GAMES
                EUROPEUS
              </h4>
              <img class="fish-image" src="../../../assets/imagens/5.png" />
            </div>
            <div class="column is-half control-text">
              <h3 class="fish-title is-1">
                SEJA O PRIMEIRO
              </h3>
              <p class="subtitle">
                Largue na frente na hora de montar seus times para competir nos
                fantasy games da elite europeia e mostre para os seus amigos que
                você também é um mito no velho continente!
              </p>
            </div>
            <div v-if="$mq === 'sm'" class="column is-half centralize">
              <h4 class="fish-subtitle">
                PRIMEIRA PLATAFORMA DE ANÁLISE EXCLUSIVA PARA FANTASY GAMES
                EUROPEUS
              </h4>
              <img class="fish-image" src="../../../assets/imagens/5.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionFishing",
};
</script>

<style lang="scss" scoped>
#section-fishing {
  width: 100%;
  height: auto;
  display: table;
  background-image: url("../../../assets/imagens/background-escala10.jpg");
  background-position: top;
  background-size: cover;

  .section-fish {
    padding: 2em 0 0 0;
  }
  .fish-image {
    vertical-align: middle;
    width: 100%;
  }

  .control-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .subtitle {
      margin-top: 1em;
      width: 60%;
    }

    @media (max-width: 768px) {
      .subtitle {
        width: 100%;
        text-align: center;
        font-size: 0.8em !important;
      }
    }
  }

  .fish-title {
    font-weight: bold;
    width: 60%;
    text-transform: uppercase;
    font-size: 2.5em;
    color: $primary;
    text-align: left;
    line-height: 1.3em;
    text-align: center;

    @media (max-width: 768px) {
      margin-top: 2em;
      text-align: center;
      font-size: 1em;
      width: 100%;
    }
  }
  .is-2 {
    font-size: 1.7em;

    @media (max-width: 768px) {
      font-size: 0.9em;
    }
  }
  .is-1 {
    font-size: 4em;

    @media (max-width: 768px) {
      font-size: 1.7em;
    }
  }
  .fish-subtitle {
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
    color: $primary;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 0.9em;
    }
  }
}
</style>
